import { InvoiceStatusEnum } from '../.generated/globalTypes';

export default function getInvoiceStatusColor(status: InvoiceStatusEnum, index: number = 0) {
  switch (status) {
    case InvoiceStatusEnum.DRAFT:
      return 'bg-warning';
    case InvoiceStatusEnum.PAID:
    case InvoiceStatusEnum.SALARY_PAID:
    case InvoiceStatusEnum.SALARY_PAID_CUSTOMER_PAID:
      return 'bg-success';
    case InvoiceStatusEnum.SALARY_PAID_CUSTOMER_NOT_PAID:
      return index === 0 ? 'bg-success' : 'bg-warning';
    case InvoiceStatusEnum.CANCELLED:
      return 'bg-secondary';
    case InvoiceStatusEnum.LATE_PAYMENT:
    case InvoiceStatusEnum.DEBT_COLLECTION:
    case InvoiceStatusEnum.DENIED_BY_COMPANY:
      return 'bg-danger';
    default:
      return 'bg-primary';
  }
}
