import { useMutation } from '@apollo/client';
import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';
import useToast from '~/features/shared/hooks/useToast';
import { InvoiceStatusEnum } from '~/.generated/globalTypes';
import { UpdateInvoiceStatusMutation as UpdateInvoiceStatusMutationType } from './graphql/.generated/UpdateInvoiceStatusMutation';
import UpdateInvoiceStatusMutation from './graphql/UpdateInvoiceStatusMutation.gql';
import useNumberParser from '~/utils/useNumberParser';

type UpdateInvoicesHookArgs = {
  onCompleted?: (data: UpdateInvoiceStatusMutationType) => void;
};

export default function useUpdateInvoiceStatus({ onCompleted }: UpdateInvoicesHookArgs = {}) {
  const { toast } = useToast();
  const { t } = useTranslation('invoices');
  const { parseNumber } = useNumberParser();

  const [updateMutation, { loading: updating, error: updateError }] =
    useMutation<UpdateInvoiceStatusMutationType>(UpdateInvoiceStatusMutation, {
      refetchQueries: ['InvoicesTableQuery', 'SalaryPaymentListQuery', 'InvoiceDetailsQuery'],
      onCompleted: (data) => {
        toast(t('messages.updated'), { variant: 'success' });
        if (onCompleted) onCompleted(data);
      },
      onError: () => toast(t('errors.update'), { variant: 'error' })
    });

  const updateInvoiceStatus = useCallback(
    (id: number, status: InvoiceStatusEnum, paidAmountDkk?: string | number) => {
      updateMutation({
        variables: { data: { status, paidAmountDkk: parseNumber(paidAmountDkk) }, where: { id } }
      });
    },
    [updateMutation, parseNumber]
  );

  return {
    updateInvoiceStatus,
    updating,
    updateError
  };
}
