import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import BookInvoiceModal from './BookInvoiceModal';

type BookInvoiceButtonProps = {
  onConfirm: (sendViaEmail: boolean, sendViaEAN: boolean) => void;
  className?: string;
};

export default function BookInvoiceButton({ className, onConfirm }: BookInvoiceButtonProps) {
  const { t } = useTranslation('invoices');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button type="button" className={classNames('btn btn-primary', className)} onClick={handleShow}>
        <i className=" uil-book-alt" /> {t('buttons.bookInvoice')}
      </button>
      <BookInvoiceModal onConfirm={onConfirm} onClose={handleClose} show={show} />
    </>
  );
}
