import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';

type EditButtonProps = {
  href: string;
  className?: string;
  showTitle?: boolean;
};

export default function EditButton({ href, className = 'btn-sm', showTitle = true }: EditButtonProps) {
  const { t } = useTranslation('common');
  return (
    <Link href={href} className={classNames('btn btn-primary', className)}>
      <i className={classNames('uil-pen', { 'me-0': !showTitle })} /> {showTitle && t('buttons.edit')}
    </Link>
  );
}
