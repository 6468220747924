import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

type CardItemProps = {
  label: JSX.Element | string;
  value?: JSX.Element | string | number | null | boolean;
  className?: string;
  icon?: JSX.Element;
  isHideable?: boolean;
  hiddenValue?: CardItemProps['value'];
  hideButtonTestId?: string;
  hiddenValueTestId?: string;
  valueTestId?: string;
};

export default function CardItem({
  label,
  value,
  className = 'mb-1',
  icon,
  isHideable,
  hiddenValue,
  hideButtonTestId,
  valueTestId,
  hiddenValueTestId
}: CardItemProps) {
  const { t } = useTranslation('common');
  const [isHidden, setHidden] = useState(true);

  let displayValue = isHideable && isHidden ? hiddenValue : value;
  if (typeof value === 'boolean') {
    displayValue = value ? (
      <span className="text-success">
        <span className="circle bg-success" />
        <span className="ms-1 text-uppercase fs-6 fw-bold">{t('labels.yes')}</span>
      </span>
    ) : (
      <span className="text-danger">
        <i className="circle bg-danger" />
        <span className="ms-1 text-uppercase fs-6 fw-bold">{t('labels.no')}</span>
      </span>
    );
  }

  return (
    <div className={classNames('card-item', className)}>
      <div className="d-flex align-items-center  gap-1">
        {icon && icon}
        <div className="label fw-bold text-muted">{label}</div>
        {isHideable && (
          <button
            type="button"
            className="hide-button btn p-0"
            onClick={() => {
              setHidden((prevState) => !prevState);
            }}
            data-testid={hideButtonTestId}
          >
            <i className={classNames('m-0', { 'uil-eye': isHidden, 'uil-eye-slash': !isHidden })} />
          </button>
        )}
      </div>
      <div className="value" data-testid={isHideable && isHidden ? hiddenValueTestId : valueTestId}>
        {displayValue || <>&nbsp;</>}
      </div>
      <style jsx>{`
        .card-item :global(.circle) {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          display: inline-block;
        }
        .hide-button {
          display: flex;
          align-items: center;
          justify-content: center;
          border: solid 1px #dfdfdf;
          border-radius: 50%;
          box-shadow: none;
          height: 19px;
          width: 19px;
        }
        .hide-button:hover {
          background-color: #f8f8ff;
        }
        .hide-button > i {
          display: flex;
          align-items: center;
          font-size: 0.75rem;
        }
      `}</style>
    </div>
  );
}
