import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import { InvoiceStatusEnum, TaskStatusEnum } from '../../../.generated/globalTypes';
import useUser from '~/contexts/User/useUser';
import InvoiceStatusBadge from './InvoiceStatusBadge';

type InvoiceBadgesProps = {
  invoice: {
    status: InvoiceStatusEnum;
    task?: { status?: TaskStatusEnum };
    overdue: boolean;
    earlyPayment: boolean;
  };
  className?: string;
};

function InvoiceBadges({ invoice, className = 'm-0 ms-1 mb-1' }: InvoiceBadgesProps) {
  const { isAdmin } = useUser();
  const { t } = useTranslation('invoices');

  return (
    <>
      <InvoiceStatusBadge className={className} status={invoice.status} />
      {isAdmin && invoice?.task?.status === TaskStatusEnum.APPROVED && (
        <span className={classNames('badge bg-success', className)}>
          {t('statuses.TASK_APPROVED').toLowerCase()}
        </span>
      )}
      {isAdmin &&
        invoice?.task?.status &&
        invoice?.task?.status !== TaskStatusEnum.APPROVED &&
        invoice?.status !== InvoiceStatusEnum.AWAITING_TASK_APPROVAL && (
          <span className={classNames('badge bg-warning ms-1 mb-1', className)}>
            {t('statuses.AWAITING_TASK_APPROVAL').toLowerCase()}
          </span>
        )}
      {isAdmin && invoice.status === InvoiceStatusEnum.SENT_TO_COMPANY && invoice.overdue && (
        <span className={classNames('badge bg-danger', className)}>
          {t('statuses.OVERDUE').toLowerCase()}
        </span>
      )}
      {isAdmin && invoice.earlyPayment && (
        <span className={classNames('badge bg-success', className)}>
          {t('statuses.EARLY_PAYMENT').toLowerCase()}
        </span>
      )}
    </>
  );
}

export default InvoiceBadges;
