import { useMutation } from '@apollo/client';
import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';
import useToast from '~/features/shared/hooks/useToast';

import { SendInvoiceMutation as SendInvoiceMutationType } from './graphql/.generated/SendInvoiceMutation';
import SendInvoiceMutation from './graphql/SendInvoiceMutation.gql';

type SendInvoiceHookArgs = {
  onCompleted?: (data: SendInvoiceMutationType) => void;
};

export default function useSendInvoice({ onCompleted }: SendInvoiceHookArgs = {}) {
  const { toast } = useToast();
  const { t } = useTranslation('invoices');
  const [sendMutation, { loading: updating, error: updateError }] = useMutation<SendInvoiceMutationType>(
    SendInvoiceMutation,
    {
      refetchQueries: ['InvoicesTableQuery', 'SalaryPaymentListQuery'],
      onCompleted: (data) => {
        toast(t('messages.updated'), { variant: 'success' });
        if (onCompleted) onCompleted(data);
      },
      onError: () => toast(t('errors.update'), { variant: 'error' })
    }
  );

  const sendInvoice = useCallback(
    (id: number) => {
      sendMutation({
        variables: { where: { id } }
      });
    },
    [sendMutation]
  );

  return {
    sendInvoice,
    updating,
    updateError
  };
}
