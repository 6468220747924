import { useTranslation } from 'next-i18next';
import { useCallback, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

type BookInvoiceModalProps = {
  onConfirm: (sendViaEmail: boolean, sendViaEAN: boolean) => void;
  show: boolean;
  onClose: () => void;
};

export default function BookInvoiceModal({ onConfirm, show, onClose: handleClose }: BookInvoiceModalProps) {
  const { t } = useTranslation('invoices');
  const [sendViaEmail, setSendViaEmail] = useState(true);
  const [sendViaEAN, setSendViaEAN] = useState(true);

  const handleConfirm = useCallback(() => {
    onConfirm(sendViaEmail, sendViaEAN);
    handleClose();
  }, [onConfirm, sendViaEmail, sendViaEAN, handleClose]);

  return (
    <>
      <Modal show={show} onHide={handleClose} className="book-invoice-modal" centered>
        <Modal.Header className="border-0">
          <Modal.Title className="m-0">{t('headers.bookInvoice')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="sendViaEmail">
            <Form.Label>Send via Email</Form.Label>
            <Form.Check checked={sendViaEmail} onChange={(e) => setSendViaEmail(e.target.checked)} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="sendViaEAN">
            <Form.Label>Send via EAN</Form.Label>
            <Form.Check checked={sendViaEAN} onChange={(e) => setSendViaEAN(e.target.checked)} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="secondary" onClick={handleClose}>
            {t('buttons.cancel')}
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            {t('buttons.bookInvoice')}
          </Button>
        </Modal.Footer>
      </Modal>
      <style jsx>{`
        :global(.book-invoice-modal) {
          margin-top: -200px;
        }
      `}</style>
    </>
  );
}
