import { useMutation } from '@apollo/client';
import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';
import useToast from '~/features/shared/hooks/useToast';
import { BookInvoiceInputArgs } from '~/.generated/globalTypes';
import BookInvoiceMutation from './graphql/BookInvoiceMutation.gql';

type BookInvoiceHookArgs = {
  onCompleted?: Function;
};

export default function useBookInvoice({ onCompleted }: BookInvoiceHookArgs = {}) {
  const { toast } = useToast();
  const { t } = useTranslation('invoices');
  const [sendMutation, { loading: updating, error: updateError }] = useMutation(BookInvoiceMutation, {
    refetchQueries: ['InvoicesTableQuery'],
    onCompleted: () => {
      toast(t('messages.booked'), { variant: 'success' });
      if (onCompleted) onCompleted();
    },
    onError: () => toast(t('errors.book'), { variant: 'error' })
  });

  const bookInvoice = useCallback(
    (data: BookInvoiceInputArgs) => {
      sendMutation({
        variables: { data }
      });
    },
    [sendMutation]
  );

  return {
    bookInvoice,
    updating,
    updateError
  };
}
