import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { InvoiceStatusEnum } from '../../../.generated/globalTypes';
import getInvoiceStatusColor from '../../../utils/getInvoiceStatusColor';

type InvoiceStatusProps = {
  status: InvoiceStatusEnum;
  upperCase?: boolean;
  className?: string;
};

function InvoiceStatusBadge({ status, upperCase, className = undefined }: InvoiceStatusProps) {
  const { t } = useTranslation('invoices');

  const statuses = t(`statuses.${status}`).split(' - ');

  return (
    <>
      {statuses.map((subStatus, i) => {
        const statusValue = upperCase ? subStatus.toUpperCase() : subStatus.toLowerCase();
        return (
          <span
            data-status={status}
            key={statusValue}
            className={classNames('badge', getInvoiceStatusColor(status, i), className)}
          >
            {statusValue}
          </span>
        );
      })}
    </>
  );
}

export default InvoiceStatusBadge;
