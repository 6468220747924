import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

type ViewEconomicInvoiceButtonProps = {
  showTitle?: boolean;
  className?: string;
  url: string;
};

export default function ViewEconomicInvoiceButton({
  showTitle = false,
  className = 'btn-sm ms-1',
  url
}: ViewEconomicInvoiceButtonProps) {
  const { t } = useTranslation();
  return (
    <a
      target="_blank"
      href={url}
      className={classNames('btn btn-outline-primary', className)}
      rel="noreferrer"
    >
      <i className="uil-file-search-alt me-0" />
      {showTitle && t('buttons.invoices.view')}
    </a>
  );
}
